import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { persistor, store } from "src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "src/components/modal/ModalLoading";
import LoadingฺV2 from "src/components/modal/ModalLoadingClock";
import "./i18n";

const TheContent = React.lazy(() => import("src/components/TheContent"));

function App() {
  return (
    <>
      <LoadingฺV2 />
      <Suspense fallback={null}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <TheContent />
          </PersistGate>
        </Provider>
      </Suspense>
    </>
  );
}

export default App;
