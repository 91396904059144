import jwt_decode from "jwt-decode";

(() => {
  if (window.token) {
    for (let tkey in window.token)
      if (window.token[tkey])
        window.token[tkey] = window.token[tkey].split("").reverse().join("");
    let tk = [window.token["f"], window.token["m"], window.token["l"]].join(
      "."
    );
    let decodedTK = jwt_decode(tk);
    window.env = decodedTK;
  }
  if (!window.env) window.env = {};
  for (const envKey in process.env) {
    if (!window.env[envKey]) window.env[envKey] = process.env[envKey];
  }
  window.env.modal_bg_header = `
  .modal_modal_bg>div>.modal-content>.modal-header{
    background: ${window.env.REACT_APP_BG_MODAL_HEADER};
    border: ${window.env.REACT_APP_BORDER_MODAL_HEADER};
  }
    .modal_modal_bg>div>.modal-content>.modal-body{
    background: ${window.env.REACT_APP_BG_MODAL_BODY};
  }
  .modal{
    padding-left: 0px !important;
  }
  `;
})();
